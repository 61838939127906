import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const Swift82002 = () => {
    const info ={
        title:"Swift E/PO at Sonoma State University August 2002",
        presenter:'Dr. Lynn Cominsky',
        presentationType:'E/PO Report',
        folder:'powerpoints/EPO_August_2002',
        folderLength:15,
        slides:null,
        ppt:'08_02_02.ppt',
        pptx:'08_02_02.pptx'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`/${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <h2>{info.presenter}</h2>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default Swift82002